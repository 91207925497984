

.playing-controle-wrap{
   
	.adm-progress-circle-svg .adm-progress-circle-fill{
		transition:all 0s;
	}

	.playing-controle-circle-wrap{
		position: relative;
		width:150px;
		overflow: hidden;
		button.play-speed-bt{
			font-size: 14px;
			line-height: 30px;
		}
		button.play-pause-bt{
			border-radius: 50%;
			overflow: hidden;
			svg{
				margin-top: 5px;
			}
		}
		button.play-close-bt{
			line-height: 30px;
			font-size: 18px;
		}
	}

	.progress-bar-wrap{
		position: absolute;
        left: 12%;
		right: 12%;
        top: -35px;
	}
}


