*{
	margin: 0;
	padding:0;
}

.slider-wrap{
	right:10px;
	top: 150px;
	position: absolute;
	width: 40px;
	transition: background-color .2s;
	z-index: 0;
	height: 300px;
	box-sizing: border-box;
	// background-color:#fff;
	border-radius: 12px;
	margin: 0;
	padding: 0;

	.slider-tap{
		position: absolute;
		width: 100%;
		height: 40px;
		cursor: pointer;
		text-align: center;
		line-height: 40px;
		background-image: url(./img/jingxiang-l-B.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 55%;
		transition: .2s all;
		opacity: .45;
	}

	.slider-tap.sub{
		top:15px;
		background-image: url(./img/+-b.png);
	}
	.slider-tap.add{
		bottom:5px;
		background-image: url(./img/--b.png);
	}
	.slider-tap:active{
		opacity: .8;
	}

	.ant-slider-vertical{
		position: absolute;
		left: 17px;
		right: 0;
		bottom:60px;
		top:75px;
		padding:0;
		height: auto;
		width: 10px;
		margin:0;
		.ant-slider-track,.ant-slider-step,.ant-slider-rail{
			width: 4px;
		}
		// .ant-slider-track{
		// 	background-color: #ff411c;
		// }
		.ant-slider-rail{
			background-color: #cbcbcb;
		}
		.ant-slider-handle{
            width: 21px;
			height: 21px;
			margin-left:-10px;
			//margin-top:-9px;
		}
	}

	.ant-slider .ant-slider-handle::after {
		inset-block-start: 0;
		inset-inline-start: 0;
		width: 21px;
		height: 21px;
	}
}


.dark .slider-wrap{
	.ant-slider-vertical{
		position: absolute;
		left: 23px;
		right: 0;
		bottom:60px;
		top:140px;
		padding:0;
		height: auto;
		width: 10px;
		margin:0;

		.ant-slider-track,.ant-slider-step,.ant-slider-rail{
			width: 10px;
		}
		.ant-slider-handle{
            width: 26px;
			height: 26px;
			margin-left:-8px;
		}
	}
}