.search-wrap{
	overflow: hidden;

	.search-bar-wrap{
		position: absolute;
		top:10px;
		left:10px;
		right:10px;
		height: 40px;

		.AutoComplete-wrap{
			position: absolute;
			left:0px;
			top: 0px;
			right:70px;
			color:#999;
			cursor: pointer;
			.ant-input-wrapper{
				.ant-input-affix-wrapper{
					border-radius: 5px;
					overflow: hidden;
				}
			}
			.anticon.ant-input-clear-icon, .ant-input-clear-icon{
				font-size: 15px;
			}
			.ant-input-group-addon{
				display: none;
			}
		}
		.searching-cancel-bt{
			position: absolute;
			top: 0px;
			right:0px;
			width: 60px;
			font-size: 14px;
			line-height: 26px;
			padding: 7px 8px;
			height: 40px;
			color:#999;
		}
	}

	.search-bottom-panel{
		position: absolute;
		top: 50px;
		left:0px;
		right:0px;
		z-index: 99;
		bottom:10px;
		overflow: hidden;
		overflow-y: auto;
			
		.search-top-full{
			padding-top: 5px;
			padding-bottom: 0px;
			background-color: #fff;
			height: auto;
			.history-contain-wrap{
				padding:0;
				margin:0;
				height: auto;
				overflow: auto;
				margin-top: 5px;
				border-bottom: 1px solid #eee;
				padding-bottom: 3px;
				margin-bottom: 10px;

				.history-title{
					height: 30px;
					font-size: 13px;
					float: left;
					width: 60px;
					line-height: 30px;
					margin-left: 10px;
					color: #777;
					border-right:1px solid rgba(0,0,0,0.15);
				}
				.clear-history-wrap{
					height: 30px;
					font-size: 14px;
					float: right;
					width: auto;
					margin-right: 10px;
					line-height: 30px;
				}
				.history-item-wrap{
					height: auto;
					transition: all .2s;
					overflow: hidden;
					padding:0;
					float: left;
					width: calc(100% - 110px);
					max-height: 60px;
					overflow: hidden;
					overflow-y: auto;
					.history-item{
						font-size: 13px;
						width: auto;
						float: left;
						margin-left: 10px;
						height: 30px;
						line-height: 30px;
						padding: 0 10px;
						color: #444;
						overflow: hidden;
						cursor: pointer;
					}
				}
				.history-item-wrap.moreHistory{
					max-height: 300px;
					overflow-y: auto;
				}
				.history-more-wrap{
					height: 25px;
					margin-top: 10px;
					display: block;
					text-align: center;
					width: 30%;
					margin-left: 35%;
					padding: 0;
					border-radius: 2px;
					line-height: 25px;
					font-size: 13px;
					color:#444;
					background-color: #f9f9f9;
					// border-bottom: 1px solid #eee;
				}
			}

			.filter-wrap{
				width: 100%;
				height: auto;
				padding: 0 20px;
				
				button{
					margin: 0;
					font-size: 14px;
					margin-left: 12px;
					margin-bottom: 6px;
					float: left;
					height: 36px;
				}
				button.active{
					background-color:rgb(253, 227, 207);
					color:rgb(245, 106, 0);
				}
			}

			.searchMuscle-wrap{
				padding:0;
				margin:0;
				margin-top: 5px;
				padding-bottom: 3px;
				margin-bottom: 10px;
				box-sizing: border-box;

				.searchMuscle-item-title{
					height: 30px;
					font-size: 13px;
					width: 100%;
					line-height: 30px;
					margin-left: 10px;
					color: #777;
				}

				.searchMuscle-item-wrap{
					height: auto;
					transition: all .2s;
					padding:0;
					width: 100%;
					max-height: 120px;
					overflow: hidden;
					overflow-y: auto;
					box-sizing: border-box;
					Button.searchMuscle-item{
						font-size: 13px;
						float: left;
						margin-left: 10px;
						width: calc(50% - 15px);
						margin-top: 7px;
						span.highlight{
							color:#ffb100;
							font-weight: 600;
						}
					}
				}
				@media screen and (min-width: 640px) {
					.searchMuscle-item-wrap::-webkit-scrollbar{
						width: 5px;
						background-color:#f1f1f1;
					}
					.searchMuscle-item-wrap::-webkit-scrollbar-track{
						border-radius:10px;
						background-color:#f1f1f1;
					}
					.searchMuscle-item-wrap::-webkit-scrollbar-thumb{
						border-radius:5px;
						background-color:#aaa;
					}
				}
				.searchMuscle-item-wrap.moreMuscle{
					max-height: 500px;
					overflow-y: auto;
				}
				.searchMuscle-more-wrap{
					height: 25px;
					margin-top: 10px;
					display: block;
					text-align: center;
					width: 30%;
					margin-left: 35%;
					padding: 0;
					border-radius: 2px;
					line-height: 25px;
					font-size: 13px;
					color:#444;
					background-color: #f9f9f9;
					// border-bottom: 1px solid #eee;
				}
			}
							
		}

		.search-contain-full{
			height: auto;
			.filter-contain-wrap{
				margin-right: 10px;
				// overflow: hidden;
				position: relative;
				padding-bottom: 10px;
				min-height: 30vh;
				overflow: hidden;
				overflow-y: auto;
				
				.find-action-total-title{
					line-height: 30px;
					color:#666;
					font-size: 13px;
					padding-left: 10px;
					margin-top: 10px;
				}
				.search-filter-contain-wrap-loading{
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					z-index: 99;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.filter-pagination-wrap{
				margin-left:10px;
				margin-top: 30px;
				width:100%;
				clear: both;
				display: block;
			}
		}
			
	}
}


.adm-list-body-inner {
    margin-top: -1px;
    max-height: 50vh;
    overflow-y: auto;
}
.adm-dropdown-popup{
	max-width: 500px;
}