.no-instrument-action-wrap{
    position: relative;
	z-index: 7777;

	.action-wrap-loading{
		position: absolute;
		top:0px;
		bottom: 0;
		left: 0;
		z-index: 999;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fff;
	}
    .action-list{
        background-color: #fff;
        overflow: hidden;
        overflow-y: auto;
    }
}




