
.instrument-action-wrap {
	background-color:#fff;
	box-sizing: border-box;
	position: relative;
	min-height: 160px;

	.instrument-action-list-wrap::-webkit-scrollbar{
		width: 5px;
		background-color:#f1f1f1;
	}
	.instrument-action-list-wrap::-webkit-scrollbar-track{
		border-radius:10px;
		background-color:#f1f1f1;
	}
	.instrument-action-list-wrap::-webkit-scrollbar-thumb{
		border-radius:5px;
		background-color:#ccc;
	}
	.instrument-action-list-wrap {
		// position: absolute;
		// top:0px;
		// right: 0;
		// bottom: 0;
		// overflow: hidden;
		// overflow-y: scroll;
		padding-left: 5px;
		padding-bottom: 120px;
		.action-list{
			clear: both;
			overflow: hidden;
		}
		h2 {
			display: block;
			clear: both;
			margin: 0;
			color: #555;
			font-size: 18px;
			padding: 10px 0;
			padding-left: 10px;
		}
	}
}







