
.webgl-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .contain-wrap{
        position: absolute;
        top:0px;
        left: 0;
        right: 0;
        bottom: 0px;
        overflow: hidden;

        .left{

        }

        .right{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            transition: .3s all;
            
            .webgl {
                position: relative;
                height: 100%;
                width: 100%;
                overflow: hidden;
                user-select: none;
                //background-image: url('src/img/logo.png');
                //background-size:100% 100%;
                //background: #f1f1f1;
    
                #webglDom {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    right: 0;
                }
        
                .name-wrap{
                    position: absolute;
                    left: 50%;
                    top:10px;
                    color:#777;
                    transform: translateX(-50%);
                    z-index: 99;
                }
        

                //notice通知栏样式修改
                .am-notice-bar {
                    background-color: rgba(24, 26, 30, .5);
                    border-radius: 18px;
                    color: #ffcc33;
                }
        
                .hide {
                    display: none;
                }
        
            }
    
            .webgl.dark {
        
                .toggle-tap{
                    background-color: rgba(0,0,0,.4);
                    background-image: url('src/img/jiantou-W.png');
                    border:1px solid rgba(255,255,255,.4);
                    svg{
                        color:#fff;
                    }
                }
                .toggle-tap:hover {
                    border: 1px solid rgba(255,255,255,.5);
                }
                .toggle-tap:before {
                    border: 1px solid rgba(255,255,255,.5);
                }
            
        
                .am-notice-bar {
                    background-color: rgba(0, 0, 0, .4);
        
                }
        
                .return-bt {
                    color: #ccc;
                }
                .return-bt:hover{
                    color: #999;
                }
        
        
            }
        }

    }
}



:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}