
.my-scrollbar-style::-webkit-scrollbar{
    width: 5px;
    background-color:#f1f1f1;
}
.my-scrollbar-style::-webkit-scrollbar-track{
    border-radius:10px;
    background-color:#f1f1f1;
}
.my-scrollbar-style::-webkit-scrollbar-thumb{
    border-radius:5px;
    background-color:#ccc;
}