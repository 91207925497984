.xuegong-wrap{
    position: fixed;
    width: 40%;
    z-index: 99;
    left: 0;
    bottom: 20px;

    .xuegong-close-wrap{
        position: absolute;
        bottom: 30px;
        left: 0;
        button{
            height: 35px;
            padding: 0 10px;
            line-height: 35px;
            max-width: calc(40vw - 10px);
            overflow: hidden;
            span{
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
                width: 100%;
            }
        }
        margin-top: 15px;
        margin-left: 15px;
        overflow: hidden;
    }

    .xuegong-item-wrap{
        position: absolute;
        left: 0;
        bottom: 80px;
        z-index: 999;
        height: auto;
        margin-top: 10px;
        max-width: 300px;
        max-height: 70vh;
        overflow: hidden;
        overflow-y: auto;
        padding: 0 5px;
        border-radius: 5px;

        button{
            height: auto;
            display: block;
            padding: 6px 8px;
            word-break: break-all;
            white-space: normal;
            font-size: 13px;
            margin-bottom: 10px;
            text-align: left;
        }
        p{
            line-height: 25px;
            font-size: 15px;
            padding: 10px 0;
            border-radius: 5px;
        }
    }
}