*{
    margin:0;
    padding:0; 
    -webkit-tap-highlight-color:transparent;
}
body{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
#root {
    position: absolute;
    left: 0;
    right: 0;
    bottom:0;
    top:0;
    -webkit-tap-highlight-color:transparent;
}
:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}
:root:root{
    --adm-color-primary: #ffcc33;
    --adm-color-primary: #ffcc33;
    --adm-color-success: #00b578;
    --adm-color-warning: #ff8f1f;
    --adm-color-danger: #ff3141;
    
    --adm-color-white: #ffffff;
    --adm-color-text: #333333;
    --adm-color-text-secondary: #666666;
    --adm-color-weak: #999999;
    --adm-color-light: #cccccc;
    --adm-color-border: #eeeeee;
    --adm-color-box: #f5f5f5;
    --adm-color-background: #ffffff;
    
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}