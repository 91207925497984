//屏幕居中
.interact-help-wrap {
	position: absolute;
	top: 50%;
	left: 45%;
	width: auto;
	height: auto;
	right: 70px;
	z-index: 5;
	background-color: rgba(0,0,0,.4);
	//使用动画
	animation: top-bottom 4s linear infinite;
	line-height: 20px;
	padding: 8px 8px;
	padding-left: 35px;
	border-radius: 10px;
	max-width: 200px;
	
	.interact-img{
		position: absolute;
		left:5px;
		top:5px;
		height: 25px;
		width: 25px;
		display: block;
		float: left;
		background-image: url('../../pages/home/src/img/dianji-W.png');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 20px;
	}
	span.help-title{
		color:#fff;
		font-weight: 400;
		font-size: 14px;
	}
}
//左右来回动画
@keyframes left-right {
	0% {
		transform: translateX(-50%);
	}
	50% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

//上下来回动画
@keyframes top-bottom {
	0% {
		transform: translateY(-50%);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-50%);
	}
}


