*{
	padding: 0;
	margin: 0;
}
	
.tap-change{
	position: fixed;
	bottom:185px;
	left: 0;
	z-index: 7;
	margin-left: 3px;
	width: auto;
	text-align: left;
	transition: all .3s;
	padding: 0;
	button{
		max-width: 100%;
		overflow: hidden;
		font-size: 14px;
		color: #666;
		text-overflow: ellipsis;
	}
}
@media screen and (min-width: 500px) {
	.tap-change{
		top:10px;
		left: 500px;
		bottom: auto;
		margin-left: 20px;
	}
}

.tab-bar-wrap{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 500px;
	z-index: 10;
	background-color: #fff;
	box-shadow: 0 0 2px rgba(0,0,0,.1);
	.tab-bar-wrap-innner{
		margin-bottom:0;
		margin-bottom:env(safe-area-inset-bottom);
	}
}
// @media screen and (min-width: 500px) {
.tab-bar-wrap.isPc{
	position: fixed;
	bottom: auto;
	top: 10px;
	padding-top: 10px;
	top:env(safe-area-inset-top);
}
// }

.muscle-sheet-wrap{
	.select-name-wrap{
		width: 100%;
		overflow: hidden;
		height: 40px;
		margin-top: 0px;
		position: relative;
		text-align: center;
		.select-name{
			width:calc(100%);
			text-align: center;
			padding-left:10px;
			line-height: 40px;
			// color: #424b5a;
			font-weight: 600;
			box-sizing: border-box;
			cursor: pointer;
			font-size: 16px;
			overflow: hidden;
			user-select: none;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow-wrap: break-word;
		}
	}
	
	.inform-bt-wrap {
		width: 100%;
		box-sizing: border-box;
		// background-color:#f1f1f1;
		padding: 0 4px;
		height: auto;
		padding-bottom: 100px;
	
		.des-contain{
			width: 100%;
			overflow: hidden;
			overflow-y: auto;
			opacity: 0;
			box-sizing: border-box;
			transition: .3s all;
			.des-name-wrap{
				// color:rgb(250, 140, 22);
				font-size: 15px;
				margin-left:10px;
				margin-right:20px;
				line-height: 35px;
				padding-left: 15px;
				margin-bottom: 5px;
			}
	
			.des-item{
				height: auto;
				overflow: auto;
				width: 100%;
				padding: 6px 5px;
				box-sizing: border-box;
				.fn-bt-wrap{
					width: 100px;
					float: left;
					overflow: hidden;
					button{
						width: 100%;
						padding: 5px 0px;
						height: auto;
						font-size: 13px;
						text-align: center;
						img{
							display: inline-block;
							width: 30px;
							height: 30px;
						}
						p{
							display: inline-block;
							margin: 0;
							padding: 0;
							width: 100%;
							text-wrap: wrap;
						}
					}
				}
				.select-note-wrap{
					float: right;
					width: calc(100% - 100px);
					padding-left: 10px;
					padding-right: 0;
					box-sizing: border-box;
					overflow: hidden;
				}
			}
		}
		
		.des-contain::-webkit-scrollbar{
			width: 4px;
	
		}
		.des-contain::-webkit-scrollbar-track{
			border-radius:10px;
			background-color:#fff;
		}
		.des-contain::-webkit-scrollbar-thumb{
			border-radius:4px;
			background-color:#ccc;
		}
		.des-contain.showdes{
			opacity: 1;
			// max-height: 100px;
		}
	}
	
	.muscle-action-wrap {
		min-height: 160px;
		border-radius: 10px 10px 0 0;
		transition: .3s top;
		.muscle-action-wrap-loading {
			position: absolute;
			top:0px;
			bottom: 0;
			left: 0;
			z-index: 9999;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
		}
	}
	
	.select-muscle-wrap-loading {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 99;
		min-height: 100px;
		background-color: #fff;
		display: flex;
		// background-color: #f1f1f1;
		justify-content: center;
		align-items: center;
	}

	
}


.hide {
	display: none;
}
.BottomSheet{
	z-index: 999!important
}
.adm-floating-panel .adm-floating-panel-header{
	// height: 50px!important;
	z-index: 99999;
}
.adm-tab-bar-item-active {
    color: #ef564f;
	font-weight: 700;
}
.adm-tab-bar-wrap{
	min-height: 60px;
	.adm-tab-bar-item-title{
		margin-top: 9px;
		font-size: 13px;
	}
}
@media screen and (min-width: 640px) {
	.react-modal-sheet-scroller::-webkit-scrollbar{
		width: 5px;
		background-color:#f1f1f1;
	}
	.react-modal-sheet-scroller::-webkit-scrollbar-track{
		border-radius:10px;
		background-color:#f1f1f1;
	}
	.react-modal-sheet-scroller::-webkit-scrollbar-thumb{
		border-radius:5px;
		background-color:#aaa;
	}
}