//方向驼
.nav-tool-wrap {

	.tool-item{
		background-repeat: no-repeat;
		background-position: center;
		background-color: rgba(255, 255, 255, .85);
		border-radius: 50%;
		// float: left;
		color:rgba(0, 0, 0, .8);
		font-size: 20px;
		box-sizing: border-box;
		height: 30px;
		width: 30px;
		background-size: 30px 30px;
		transition: all .05s;
		opacity: .9;
		line-height: 30px;
		margin-top: 12px;
		margin-left: 0;
		border: none;
		padding: 0;
	}

	.tool-item.active{
		background-color: #ffe47a;
		opacity: 1;
		color:rgba(0, 0, 0, 1);
	}

	.tool-wrap{
		position: absolute;
		z-index: 2;
		background-color: rgba(255, 255, 255, .85);
		text-align: center;
		// box-shadow: 1px -1px 2px 0 rgba(28,42,63,.1),0 1px 2px 0 rgba(28,42,63,.3);
		bottom: 200px;
		right: 10px;
		width: 45px;
		height: auto;
		text-align: center;
		padding: 0px 5px;
		padding-bottom: 5px;
		transition: all .3s;
		overflow: hidden;
		box-sizing: border-box;
		border-radius: 8px;

		.expand-items-wrap{
			max-height: 0;
			opacity: 0;
			transition: all .3s;
			overflow: hidden;
		}
		.expand-items-wrap.expand{
			max-height: 180px;
			opacity: 1;
		}

		.tool-item.history-bt{
			font-size: 18px;
		}

		.tool-item.resetCamera {
			background-image: url('../../pages/home/src/img/center-B.png');
			background-size: 22px 22px;
		}
		.tool-item.panCamera{
			background-image: url('../../pages/home/src/img/pan-B.png');
			background-size: 20px 20px;
		}
		.tool-item.draw{
			background-image: url('../../pages/home/src/img/huabi-B.png');
			background-size: 20px 20px;
		}
		.tool-item.cutScreen{
			background-image: url('../../pages/home/src/img/cutScreen-B.png');
			background-size: 26px 26px;
		}
		.tool-item.jingxiang{
			background-image: url('../../pages/home/src/img/jingxiang-B.png');
			background-size: 26px 26px;
			transition: all 0s;
		}
		.tool-item.jingxiang.active{
			transform: rotate(180deg);
		}
		.slice{
			font-size: 18px;
		}
	}

	.tool-wrap.tool-history-wrap{
		z-index: 2;
		top: 95px;
        bottom: auto;
		// left: 10px;
		right: 10px;
	}

	//速隐模式提示框
	.slice-tip-wrap{
		position: fixed;
		left: 50%;
		top: -60px;
		transform: translateX(-50%);
		height: 40px;
		width: auto;
		z-index: 10;
		border-radius: 25px;
		text-align: center;
		transition: top .3s;
	
		.slice-tip-item{
			float: left;
			margin-left: 10px;
			margin-top: 4px;
			font-size: 14px;
			button{
				height: 30px;
				font-size: 16px;
				width: 30px;
				color: #555;
				padding:0;
				line-height: 30px;
				border-radius: 10px;
				overflow: hidden;
			}
			button:active{
				color: #111;
				background-color: #b0d4ff;
			}
			button:disabled{
				color: #999;
			}

		}
		.slice-tip-item.slice-tip-reset{
			button{
				width: auto;
				padding: 0 10px;
				font-size: 16px;
			}
		}
	}
	.slice-tip-wrap.active{
		top: 60px;
	}

}

@media screen  and (min-width:640px){
	//方向驼
	.nav-tool-wrap {

		.tool-item{
			font-size: 23px;
			height: 40px;
			width: 40px;
			background-size: 35px 35px;
			line-height: 40px;
		}


		.tool-wrap{
			width: 50px;
			.tool-item.search-bt{
				font-size: 25px;
			}
			.tool-item.history-bt{
				font-size: 21px;
			}
			.tool-item.jingxiang.active{
				transform: rotate(180deg);
			}
			.slice{
				font-size: 18px;
			}
		}

		.tool-wrap.tool-history-wrap{
			z-index: 2;
			top: 60px;
			bottom: auto;
			// left: 10px;
			right: 10px;
		}

		//速隐模式提示框
		.slice-tip-wrap{
			position: fixed;
			left: 50%;
			top: -60px;
			transform: translateX(-50%);
			height: 40px;
			width: auto;
			z-index: 10;
			border-radius: 25px;
			text-align: center;
			transition: top .3s;
		
			.slice-tip-item{
				float: left;
				margin-left: 10px;
				margin-top: 4px;
				font-size: 14px;
				button{
					height: 30px;
					font-size: 16px;
					width: 30px;
					color: #555;
					padding:0;
					line-height: 30px;
					border-radius: 10px;
					overflow: hidden;
				}
				button:active{
					color: #111;
					background-color: #b0d4ff;
				}
				button:disabled{
					color: #999;
				}

			}
			.slice-tip-item.slice-tip-reset{
				button{
					width: auto;
					padding: 0 10px;
					font-size: 16px;
				}
			}
		}
		.slice-tip-wrap.active{
			top: 60px;
		}

	}
}



.nav-tool-wrap.hide {
	.tool-wrap{
		right: -70px;
	}
}

.cut-img-wrap{
    .cut-item-wrap{
        width: 40%;
        height: 30px;
        margin: 0 auto;
        img{
            width: 28px;
            height: 28px;
            margin-top: 1px;
            display: inline-block;
        }
        span{
            margin-left: 20px;
            line-height: 30px;
            font-size: 16px;
            display: inline-block;
        }
    }
}

