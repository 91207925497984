
.progress-wrap{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    // background-image: url('qidongye_bg.jpg');
    background-color: #eeeeee;
    background-repeat: no-repeat;
    background-position: center;
    background-size:100% 100%;

    .progress-container {
        position: absolute;
        left: 50%;
        top: 55%;
        width: 400px;
        transform: translateX(-50%);
        max-width: 500px;

        .loading-tip-text {
            line-height: 20px;
            margin-top: 5px;
            color: #eee;
            text-align: left;
            font-size: 15px;
        }
    }
}
.progress-wrap.hide {
    display: none;
}


@media only screen and (max-width: 1024px) {
    .progress-wrap{
        .progress-container {
            width: 70%;
        }
    }
}