
.progress-component-wrap{
	
	.action-detail-wrap.loading{
		position: absolute;
		bottom:7px;
		left: 10px;
		z-index: 9;
		right: 0;
		height: 20vh;
		display: flex;
		// background-color: #f1f1f1;
		justify-content: center;
		align-items: center;
		background-color: rgba(255,255,255,.5);
		width: calc(100% - 20px);
		max-width: 500px;
		border-radius: 6px;
	}

	.action-name-panel{
		position: fixed;
		bottom:7px;
		left: 10px;
		width: calc(100% - 20px);
		z-index: 99;
		max-width: 500px;
		height: auto;
		border-radius: 6px;
		background-color: rgba(255,255,255,.65);
		padding-bottom: 10px;
		bottom: env(safe-area-inset-bottom);
		user-select: none;

		.tap-change{
			position: absolute;
			top:-70px;
			right: 2px;
			z-index: 7;
			width: auto;
			text-align: right;
			transition: all .3s;
			overflow: hidden;
			padding: 0;
			button{
				max-width: 100%;
				overflow: hidden;
				font-size: 14px;
				color: #666;
				text-overflow: ellipsis;
			}
		}

        
		.top-name-wrap{
			padding-top: 5px;
			//关闭按钮
			button.play-close-bt{
				position: absolute;
				top:2px;
				right: 0;
				z-index: 99;
				height: 35px;
				font-size: 18px;
				color: #000;
			}
			//左边动作名称
			button.action-name-text{
				box-sizing: border-box;
				overflow: hidden;
				text-wrap: wrap;
				text-align: left;
				padding: 5px 10px;
				color:#475266;
				padding-right: 50px;
				width: calc(100% - 40px);
				span.playing-name-toggle-svg{
					font-size: 12px;
					display: inline-block;
				}
				span.playing-name-toggle-svg.rotate{
					transform: rotate(90deg);
					display: inline-block;
				}
		
				span.playing-name-title{
					color:#262a31;
					margin-left: 6px;
					font-size: 15px;
					overflow: hidden;
					padding-top: 3px;
					text-overflow:ellipsis;
				}
			}
			.playing-name-divider{
				margin:10px;
				margin-top: 5px;
				border-color: #ddd;
			}
			//下面的动作释义
			.show-note{
				clear: both;
				-webkit-overflow-scrolling: touch;
				box-sizing: border-box;
				max-height: 300px;
				transition: all .3s;
				opacity: 1;
				overflow: hidden;
				overflow-y: auto;
				//结构注释
				.show-note-text{
					padding: 5px 15px;
					font-size: 15px;
					color: #555;
					b{
						font-size: 14px;
						color:#000;
					}
				}
			}
			.show-note.hidden{
				max-height: 0px;
				opacity: 0;
			}
			.show-note::-webkit-scrollbar{
				width: 5px;
				background-color:#f1f1f1;
			}
			.show-note::-webkit-scrollbar-track{
				border-radius:10px;
				background-color:#fff;
			}
			.show-note::-webkit-scrollbar-thumb{
				border-radius:5px;
				background-color:#ccc;
			}
		}

		.playing-fn-bt-wrap{
			height: auto;
			overflow: hidden;
			overflow-y: auto;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-wrap: nowrap;
			padding-bottom: 5px;
			.playing-fn-bt-wrap-left{
				width: calc(100% - 120px);
				//右边名称功能按钮
				.playing-fn-bt-wrap-left-inner{
					.playing-fn-bt-item{
						padding:0;
						margin:0;
						margin-left: 10px;
						text-align: center;
						float: left;
						color:#4f5b6f;
						overflow: hidden;
						height: 40px;
						box-sizing: border-box;
						line-height: 40px;
					}
					.playing-fn-bt-item.fixation-bt-wrap{
						button.active{
							font-size: 12px;
							background-color:#ff8f1f;
						}
					}
					.playing-fn-bt-item.gif-bt-wrap{
						button{
							font-size: 18px;
						}
					}
					.playing-fn-bt-item.collect-bt-wrap{
						button{
                            padding-top: 6px;
							font-size: 19px;

						}
					}
					.playing-fn-bt-item.reset-playing-wrap{
						button{
							margin: 0;
							font-size: 13px;
						}
					}
					.playing-fn-bt-item.error{
						font-size: 13px;
					}
				}
			}

			.playing-fn-bt-wrap-right{
				width: 120px;
			}
		}

		.assist-bt-wrap{
            position: relative;
			overflow: hidden;
			height: auto;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 0 7px;

			.assist-bt-item{
				background-color: #eee;
				box-sizing: border-box;
				float: left;
				height: 26px;
				border-radius: 13px;
				padding: 0;
				border: 1px solid #ddd;
				overflow: hidden;
				margin-top: 5px;
				.target-assist-bt.dye{
					background: #fff;
					color: #666;
				}
				.target-assist-bt.dye{
					background: #f4294f;
					color: #eee;
				}
				.synergist-assist-bt.dye{
					background: #af4fce;
					color: #eee;
				}
				.stabilizers-assist-bt.dye{
					background: #f1aa2d;
					color: #fff;
				}
				.antagonist-assist-bt.dye{
					background: #90ff00;
					color: #444;
				}
				.adm-divider-center{
					border-left: 1px solid #ccc;
					margin:0 6px;
				}
				//展示列表按钮
				button.assist-toggle-bt{
	                padding: 3px 6px;
					margin: 0;
					margin-right: 2px;
					color: #4f5b6f;
					border-radius: 0;
					font-size: 13px;
				}

			}
		}
	}	

	@media screen and(min-width:640px){

	
		.action-name-panel{

			.tap-change{
				top:-70px;
				right: auto;
				left: 0;
				button{
					font-size: 16px;
				}
			}
		}	
	}

}

.active-popup-inner{
	height: 100%;
	.adm-list-body-inner {
		margin-top: -1px;
		max-height: calc(60vh - 33px);
		overflow-y: auto;
	}
}








