.mousemove-dragger {
    position: absolute;
    z-index: 77;
    min-width: 184px;
   
    .move-name {
        height: auto;
        position: absolute;
        left: 10px;
        top: -45px;
        display: block;
        color: #fff;
        // width: auto;
        width: auto;
        padding:5px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        background:#999;
    }
}

.mousemove-dragger.hasData{
    .move-name {
        background:#333;
    }
}

.dark .mousemove-dragger {
    .move-name {
        color: #000;
    }
}