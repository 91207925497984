.collect-wrap{
    user-select: none;
}

.collect-contain-wrap{
    width: 100%;
    height: 100%;
    padding:0;margin:0;
    .collect-top-bt-wrap{
        height: 40px;
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        padding: 0 12px;
        box-sizing: border-box;
        span.collect-title{
            float: left;
            line-height: 40px;
            font-size: 15px;
        }
        button{
            float: right;
        }
    }
    .collectList-wrap{
        overflow: hidden;
        padding: 0;
        flex-wrap:wrap;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        margin:0;
        margin-top: 60px;
        ul{
            padding: 0;
            margin: 0;
            display: block;
        }

        .filter-contain-wrap{
            margin-right: 10px;
            // overflow: hidden;
            min-height: 50vh;
            position: relative;
            padding-bottom: 10px;

            
            .find-action-total-title{
                line-height: 40px;
                color:#666;
                font-size: 14px;
                padding-left: 15px;
                margin-top: 10px;
            }
    
            .filter-contain-wrap-loading{
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex;
                z-index: 999;
                background-color: #fff;
                justify-content: center;
                align-items: center;
            }
        }

        .filter-pagination-wrap{
            padding-top: 20px;
            margin-left: 20px;
            width:100%;
            clear: both;
            display: block;
        }
    }
}


.collect-panel{
	// z-index: 10001!important;
	.ant-drawer-header{
		background-color:#fff;
	}
	.ant-drawer-body{
		background-color:#fff;
		padding:0;
	}

}