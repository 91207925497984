.qzd-wrap{
    position: fixed;
    left: 0;
    bottom: 30px;
    z-index: 999;
    width: 40%;
    height: auto;
    max-width: 300px;
    max-height: 70vh;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 5px;
    .qzd-close-wrap{
        button{
            height: 35px;
            padding: 0 10px;
            line-height: 35px;
            max-width: calc(40vw - 10px);
            overflow: hidden;
            span{
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
                width: 100%;
            }
        }
        margin-top: 15px;
        margin-left: 15px;
        overflow: hidden;
    }
    .qzd-item-wrap{
        margin-top: 10px;
        margin-left: 5px;
        overflow: hidden;
        button{
            color:#f1f1f1;
            height: 35px;
            padding: 0 10px;
            line-height: 35px;
        }
        p{
            // background-color: rgba(255,255,255,.3);
            line-height: 25px;
            font-size: 15px;
            padding: 10px 0;
            border-radius: 5px;
        }
    }
}