* {
	padding: 0;
	margin: 0;
}

.draw-wrap {
	.canvasDraw {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow: hidden;
		display: block;
		z-index: 2;
		//cursor:crosshair;
	}
	.canvasDraw.hide {
		display: none;
	}

	.draw-option-wrap {
		padding: 0;
		height: 200px;
		position: fixed;
		width: 100%;
		max-width: 500px;
		left: 0px;
		bottom: -200px;
		transition: bottom .2s;
		z-index: 999;
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 1);
		border-radius: 5px;
		box-shadow: 1px -1px 2px 0 rgba(28, 42, 63, .05), 0 1px 2px 0 rgba(28, 42, 63, .2);
		//border-top: 1px solid #eee;
		

		.draw-tool-list {
			position: absolute;
			height: 40px;
			width: 100%;
			top:-45px;
			top:calc(-45px - constant(safe-area-inset-bottom));
			top:calc(-45px - env(safe-area-inset-bottom));
			background-color: #fff;

			.draw-tool-list-item {
				list-style: none;
				float: left;
				line-height: 40px;
				button {
					border-radius: 20px;
					background-color: #fff;
					margin-left: 15px;
					font-size: 14px;
					color: #292c31;
				}
			}
			.draw-tool-list-item.huabi-sign-wrap {
				position: absolute;
				left: 50%;
				margin-left: -20px;
				padding: 0;
				display: block;
				border-radius: 50%;
				float: left;
				text-align: center;
				width: 40px;
				height: 40px;
				font-size: 20px;
				line-height: 40px;
				transition: all .2s;
				display: flex;
				justify-content: center;
				align-items: center;
				.radius-sign {
					float: left;
					height: 40px;
					border-radius: 50%;
					width: 40px;
					overflow: hidden;
					position: relative;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100%;

					//background-image: url('../../pages/home/src/img/opacity.png');
					.opacity-wrap {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 14px;
						height: 14px;
						border-radius: 50%;
						transform: translate(-50%, -50%);
						opacity: .8;
						background-color: #9ee0ff;
					}
				}
			}
			.draw-tool-list-right{
				right: 0;
				top:0;
				position: absolute;
				width: auto;
				height: 34px;
				li{
					float: left;
					width: 34px;
					height: 34px;
					background-color: rgba(255, 255, 255, 1);
					line-height: 34px;
					border-radius: 50%;
					text-align: center;
					margin-right: 10px;
					overflow: hidden;
					color: #59606c;
					font-size: 20px;
					cursor: pointer;
					.ant-btn {
						color: #59606c;
					}
				}
				li.catImg {
					background-image: url('../../pages/home/src/img/cutScreen-B.png');
					background-size: 28px 28px;
					opacity: .8;
					background-position: center;
					background-repeat: no-repeat;
					transition: all .2s;
				}
				li.close-draw {
				}
				li.toggle-optionBar {

				}
			}
			// li.toggle-optionBar:hover{
			// 	background-color: #d2e2f6;
			// 	.ant-btn{
			// 		color: #322323;
			// 	}
			// }
		}

		.brush-setting-wrap{
			width: 100%;
			height: 50px;
			border-bottom: 1px dashed #ccc;
			padding: 10px 10px;
			box-sizing: border-box;
			.brush-setting-title{
				line-height: 32px;
				width: 60px;
				overflow: hidden;
				text-align: center;
				float: left;
				font-size: 12px;
				color:#666b73;
			}
	
			.brush-setting-slider-wrap{
				float: left;
				width: calc(100% - 60px);
			}
		}

		.draw-type-wrap {
			width: 100%;
			height: 32px;
			display: flex;
			justify-content: space-around;
			box-sizing: content-box;

			.draw-type-item {
				height: 32px;
				width: 32px;
				float: left;
				background-position: center;
				background-repeat: no-repeat;
				background-size: 25px 25px;
				transition: all .2s;
				// margin-left: 16px;
				border-radius: 5px;
				opacity: .7;
			}

			.draw-type-item:hover{
				transform: scale(1.1);
				background-color: #e1f2ff;
				opacity: 1;
			}
			.draw-type-item.active {
				opacity: 1;
				// transform: scale(1.1);
				background-color: #ffdd00;
			}

			.draw-type-item.brush {
				background-image: url('../../pages/home/src/img/brush-B.png');
			}

			.draw-type-item.line {
				background-image: url('../../pages/home/src/img/line-B.png');
			}

			.draw-type-item.rectangle {
				background-image: url('../../pages/home/src/img/rectangle-B.png');
			}

			.draw-type-item.circle {
				background-image: url('../../pages/home/src/img/circle-B.png');
				background-size: 30px 30px;
			}

			.draw-type-item.arrows {
				background-image: url('../../pages/home/src/img/arrows-B.png');
			}

			.draw-type-item.eraser {
				background-image: url('../../pages/home/src/img/xiangpi-B.png');
				background-size: 26px 26px;
			}


		}

		.draw-color-wrap {
			height: 35px;
			width: 100%;
			overflow: hidden;
			box-sizing: border-box;

			ul.draw-color-list {
				width: 100%;
				height: 35px;
				overflow: hidden;
				display: flex;
				justify-content: space-around;
				align-items: center;
				overflow: hidden;

				li {
					margin-top: 5px;
					padding: 0;
					width: 25px;
					height: 25px;
					float: left;
					list-style: none;
					position: relative;
					box-sizing: content-box;
					transition: all .2s;
					text-align: center;
					border: 1px solid #fff;
					border-radius: 50%;

					p {
						width: 15px;
						margin-left: 5px;
						display: block;
						height: 15px;
						margin-top: 5px;
						border-radius: 50%;
						box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
					}
				}

				li.active {
					transform: scale(1.1);
					border: 1px solid #9ee0ff;
					background-color: #d2e2f6;
				}

				li:hover{
					transform: scale(1.1);
					border: 1px solid #9ee0ff;
					background-color: #4c535b;
				}

				.colorInput {
					width: 25px;
					height: 25px;
					float: left;
					//border: none;
					list-style: none;
				}
			}
		}
	}
	@media screen  and (min-width:640px){
		.draw-option-wrap {
			left: 515px;
		}
	}

	.draw-option-wrap.active {
		bottom: 0px;
		// bottom: constant(safe-area-inset-bottom);
		bottom: env(safe-area-inset-bottom);
		.draw-tool-list{
			top:-45px;
		}
	}
}

.dark .draw-wrap {
	.draw-option-wrap {
		background-color: rgba(0, 0, 0, 1);
		box-shadow: 1px -1px 2px 0 rgba(28, 42, 63, .05), 0 1px 2px 0 rgba(28, 42, 63, .2);
		//border-top: 1px solid #eee;
		.draw-tool-list {
			.draw-tool-list-item {
				button {
					color: #f3f6fd;
					background-color: #000;
				}
				// button:disabled {
				// 	color: #dee7f6;
				// }
			}
			.draw-tool-list-right{
				li{
					color: #dadee3;
					background-color: rgba(0, 0, 0, .9);
					.ant-btn {
						color: #dadee3;
					}
				}
				li.catImg {
					background-image: url('../../pages/home/src/img/cutScreen-W.png');
				}
			}
		}

		.brush-setting-wrap{
			border-bottom: 1px dashed rgb(57, 57, 57);
			.brush-setting-title{
				color:#9d9d9d;
			}
			.brush-setting-slider-wrap{
				.adm-slider-track{
					background-color: #73767a;
				}
				.adm-slider-thumb{
					background-color: #73767a;
				}
			}
		}
		.draw-type-wrap {
			// border-bottom: 1px dashed rgb(73, 73, 73);
			// .draw-type-item:hover{
			// 	// transform: scale(1.2);
			// 	background-color: #d0f0ff;
			// }
			.draw-type-item:hover{
				transform: scale(1.1);
				background-color: #363c42;
				opacity: 1;
			}
			.draw-type-item.active {
				opacity: 1;
				background-color: #ffdd00;
				//background-color: #d0f0ff;
			}
			.draw-type-item.brush {
				background-image: url('../../pages/home/src/img/brush-W.png');
			}
			.draw-type-item.line {
				background-image: url('../../pages/home/src/img/line-W.png');
			}
			.draw-type-item.rectangle {
				background-image: url('../../pages/home/src/img/rectangle-W.png');
			}
			.draw-type-item.circle {
				background-image: url('../../pages/home/src/img/circle-W.png');
			}
			.draw-type-item.arrows {
				background-image: url('../../pages/home/src/img/arrows-W.png');
			}
			.draw-type-item.eraser {
				background-image: url('../../pages/home/src/img/xiangpi-W.png');
			}
			.draw-type-item.brush.active {
				background-image: url('../../pages/home/src/img/brush-B.png');
			}
			.draw-type-item.line.active {
				background-image: url('../../pages/home/src/img/line-B.png');
			}
			.draw-type-item.rectangle.active {
				background-image: url('../../pages/home/src/img/rectangle-B.png');
			}
			.draw-type-item.circle.active {
				background-image: url('../../pages/home/src/img/circle-B.png');
			}
			.draw-type-item.arrows.active {
				background-image: url('../../pages/home/src/img/arrows-B.png');
			}
			.draw-type-item.eraser.active {
				background-image: url('../../pages/home/src/img/xiangpi-B.png');
			}
		}

		.draw-color-wrap {
			ul.draw-color-list {
				li {
					border: 1px solid rgb(96, 96, 96);
					border-radius: 50%;

					p {
						box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
					}
				}
				li.active {
					transform: scale(1.1);
					border: 1px solid #9ee0ff;
					background-color: #d2e2f6;
				}
				// li:hover{
				//   border: 1px solid #9ee0ff;
				// }
			}
		}
	}
}