.actioni-item-wrap{
    height: auto;
    margin-bottom: 13px;
    float: left;
    list-style: none;
    border-radius: 5px;
    margin-left: 10px;
    position: relative;
    text-align: center;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(34,34,34,.1),0 7px 18px 0 rgba(34,34,34,.1);
    box-sizing: border-box;
    padding-bottom: 8px;

    .action-item-img{
        height: auto;
        min-height: 50px;
        overflow: hidden;
        width: 100%;
        img{
            transform: scale(1.5);
        }
    }
    .action-item-name{
        position: relative;
        height: 35px;
        margin-top: 10px;
        text-align: center;
        font-size: 13px;
        color:#424b5a;
        overflow: hidden;
        box-sizing: border-box;
        padding: 5px 5px;
        line-height: 15px;
        text-wrap: wrap;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        box-orient: vertical;
        text-overflow: ellipsis;
        text-align: left;
        word-break:break-all;
        .highlight{
            color:#ffb100;
            font-weight: 600;
        }
    }
    .playing{
        position: absolute;
        top: 5px;
        right: 5px;
        height: 25px;
        width: 25px;
        background-image: url(./dynamic.gif);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px 25px;
    }

    .lock-sign{
        position: absolute;
        top: 0;
        left: 0;
        height: 30px;
        width: 30px;
        font-size: 23px;
        color: #555;
        font-weight: 600;
        background-image: url(./lock.png);
        background-position: left top;
        background-repeat: no-repeat;
        opacity: 1;
        background-size: 30px auto;
    }

    .collect-bt-wrap{
		position: absolute;
        padding:0;
        top: 5px;
        right: 5px;
        height: 20px;
        width: 20px;
		font-size: 14px;
        z-index: 999;
		border-radius: 50%;
		line-height: 20px;
		color:#000;
        opacity: .7;
	}
    
    .collect-bt-wrap:hover{
		font-size: 15px;
        opacity: 1;
	}

    .Badge{
        position: absolute;
        top:45px;
        z-index: 9898989;
    }
}
.actioni-item-wrap::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border:3px solid #ffdc2c;
    display: none;
}

.actioni-item-wrap:hover{
    transition:all .2s;
    transform: scale(1.01);
    z-index: 99;
    .action-item-name{
        color:#000;
    }
}
.actioni-item-wrap:active{
    transition:all .1s;
    transform: scale(1.01);
    box-shadow: 0 1px 1px rgba(34,34,34,.3),0 7px 18px 0 rgba(34,34,34,.3);
}
.actioni-item-wrap.active::after{
    display: block;
}


.pricing-dialog{
    z-index: 999999;
}