
.gif-loading-wrap{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 8555;
    background-color: rgba(0,0,0,.6);
    .gif-loading-cennter{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
    .ant-spin-nested-loading{
        height: 100%;
        width: 100%;
    }
    .ant-spin-nested-loading div .ant-spin{
        max-height: 100%;
    }
}
.gif-loading-wrap.hide {
    display: none;
}

